import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StaticService } from './services/static.service';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Laundry App';
  showMenu: boolean = true;
  mobileViewMaxSize = 768;

  constructor(private router: Router, private loginService: LoginService) {
    if (window.innerWidth < this.mobileViewMaxSize) {
      this.showMenu = false;
      
    }
  }

  changeRoute(routeValue) {
    if (window.innerWidth < this.mobileViewMaxSize) {
      this.showMenu = false;
    }

    //this.router.navigate([routeValue]);
  }

  logout(){
    this.loginService.removeAdminTokenAtLocStorage();
    this.router.navigate(["/login"]);
  }

}
