import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Reading } from 'src/app/models/reading';
import { ReadingService } from 'src/app/services/reading.service';
import { StaticService } from 'src/app/services/static.service';

@Component({
  selector: 'app-reading',
  templateUrl: './reading.component.html',
  styleUrls: ['./reading.component.css']
})
export class ReadingComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private spinnerService: Ng4LoadingSpinnerService,
    private readingService: ReadingService) { }

  addEditFrom: FormGroup;
  errorMsg: string;
  successMsg: string;
  todayReadings: Reading[];

  ngOnInit() {
    this.createAddForm();
    this.loadTodayReading();
  }

  loadTodayReading() {
    this.spinnerService.show();
    this.readingService.getTodayReading().subscribe(
      data => {
        this.todayReadings = data;
      },
      err => {
        this.errorMsg = err;
      },
      () => this.spinnerService.hide()
    )
  }

  changeReadingType($event) {
    let readingType = $event.currentTarget.value
    let reading = this.todayReadings.find(x => x.ReadingType == readingType);

    this.addEditFrom.controls.RoomBooked.reset();
    this.addEditFrom.controls.MeterReading.reset();
    this.addEditFrom.controls.StartTime.reset();
    this.addEditFrom.controls.StopTime.reset();
    this.addEditFrom.controls.Id.setValue(0);

    if (reading === null) {
      return;
    }

    this.addEditFrom.controls.Id.setValue(reading.Id);

    if (readingType === 'MeterReading') {
      this.addEditFrom.controls.RoomBooked.setValue(reading.RoomBooked);
      this.addEditFrom.controls.MeterReading.setValue(reading.MeterReading);
    } else {
      this.addEditFrom.controls.StartTime.setValue(this.datePipe.transform(reading.StartTime, 'dd-MMM-yyyy hh:mm aa'));
      this.addEditFrom.controls.StopTime.setValue(this.datePipe.transform(reading.StopTime, 'dd-MMM-yyyy hh:mm aa'));

    }

  }

  saveMeterReading() {

    if (this.addEditFrom.invalid) {
      StaticService.validateAllFormFields(this.addEditFrom);
      console.log(this.addEditFrom.errors);
      return;
    }

    if (this.addEditFrom.controls.MeterReading.value === null
      || this.addEditFrom.controls.MeterReading.value < 0) {
      this.addEditFrom.controls.MeterReading.markAsTouched();
      this.errorMsg = "Please enter meter reading";
      return false;
    }

    if (this.addEditFrom.controls.RoomBooked.value === null
      || this.addEditFrom.controls.RoomBooked.value < 0) {
      this.addEditFrom.controls.RoomBooked.markAsTouched();
      this.errorMsg = "Please enter room booked";
      return false;
    }

    this.saveReading();
  }

  saveReading() {
    let reading: Reading = this.addEditFrom.value;
    reading.UserName = StaticService.getUserNameAtLocStorage();

    this.spinnerService.show();
    this.readingService.save(reading).subscribe(
      data => {
        this.successMsg = data;
        this.addEditFrom.reset();
        this.addEditFrom.controls.Id.setValue(0);
        this.addEditFrom.controls.DateTime.setValue(this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm aa'));
        this.loadTodayReading();
      },
      err => {
        this.errorMsg = err;
      },
      () => this.spinnerService.hide()
    )
  }

  saveStartTime() {
    if (this.addEditFrom.invalid) {
      StaticService.validateAllFormFields(this.addEditFrom);
      console.log(this.addEditFrom.errors);
      return;
    }

    this.addEditFrom.controls.StartTime.setValue(this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm aa'));
    this.saveReading();
  }

  saveStopTime() {

    if (this.addEditFrom.invalid) {
      StaticService.validateAllFormFields(this.addEditFrom);
      console.log(this.addEditFrom.errors);
      return;
    }

    this.addEditFrom.controls.StopTime.setValue(this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm aa'));
    this.saveReading();
  }


  createAddForm(): void {
    this.addEditFrom = this.formBuilder.group({
      Id: [0],
      DateTime: [this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm aa'), Validators.required],
      ReadingType: [, Validators.required],
      RoomBooked: [],
      MeterReading: [],
      StartTime: [],
      StopTime: [],
      User: []
    })
  }

}
