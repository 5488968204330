import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StaticService } from './static.service';
import { Bill } from '../models/bill';
import { Observable } from 'rxjs';
import { BillItem } from '../models/bill-item';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = StaticService.BaseServiceUrl + "Bill";

  public get(searchItems): Observable<Bill[]> {
    let url=this.ServiceUrl+'/GetBillBySearch';
    return this.httpClient.post<Bill[]>(url,searchItems);
  }

  public getBillItem(billId: number): Observable<BillItem[]> {
    let url = this.ServiceUrl + '/GetBillItems/' + billId
    return this.httpClient.get<BillItem[]>(url);
  }

}
