import { Component, OnInit } from '@angular/core';
import { StockItem } from 'src/app/models/stock-item';
import { StockService } from 'src/app/services/stock.service';
import { ItemService } from 'src/app/services/item.service';
import { forkJoin } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ItemMaster } from 'src/app/models/item-master';

@Component({
  selector: 'app-stock-report',
  templateUrl: './stock-report.component.html',
  styleUrls: ['./stock-report.component.css']
})
export class StockReportComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,
    private stockService: StockService,
    private itemService: ItemService) { }

  stockItemList: StockItem[] = [];
  itemList: ItemMaster[] = [];
  successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {
    this.loadStockData();
  }

  loadStockData() {
    let stockRequest = this.stockService.getStockItems();
    let itemRequest = this.itemService.getItems();

    this.spinnerService.show();
    forkJoin([stockRequest, itemRequest]).subscribe(
      data => {
        this.stockItemList = data[0];
        this.itemList = data[1];
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  getItemName(itemId: any) {
    return this.itemList.find(item => item.Id === parseInt(itemId)).DisplayItemName;
  }

  calculateTotal(stockItem: StockItem) {
    return stockItem.MainStock + stockItem.RunningStock + stockItem.AtRoom + stockItem.Laundry + stockItem.Damage;
  }

}
