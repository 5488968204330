import { Component, OnInit } from '@angular/core';
import { InwardOutward } from 'src/app/models/inward-outward';
import { InwardOutwardService } from 'src/app/services/inward-outward.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-inward-outward-list',
  templateUrl: './inward-outward-list.component.html',
  styleUrls: ['./inward-outward-list.component.css']
})
export class InwardOutwardListComponent implements OnInit {

  constructor(private inwardOutwardService: InwardOutwardService,
    private spinnerService: Ng4LoadingSpinnerService) { }

  inwardOutwardList: InwardOutward[] = [];
  successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {
    this.loadGridData();
  }

  loadGridData() {
    this.spinnerService.show();
    this.inwardOutwardService.get().subscribe(
      data => {
        this.inwardOutwardList = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  deleteInOut(inOutId: number) {

    if (!confirm("Are you sure to delete data")) {
      return;
    }

    this.spinnerService.show();
    this.inwardOutwardService.delete(inOutId).subscribe(
      data => {
        this.successMsg = data;
        this.loadGridData();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      });
  }

  createBillFromOldEntry() {
    this.inwardOutwardService.createBillFromOldEntry().subscribe(
      data => console.log(data),
      err => console.log(err)
    )
  }



}
