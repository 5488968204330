import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InwardOutwardComponent } from './components/inward-outward/inward-outward.component';
import { InwardOutwardListComponent } from './components/inward-outward-list/inward-outward-list.component';
import { StockReportComponent } from './components/stock-report/stock-report.component';
import { BillListComponent } from './components/bill-list/bill-list.component';
import { LoginComponent } from './components/login/login.component';
import { ReadingComponent } from './components/reading/reading.component';
import { ReadingReportComponent } from './components/reading-report/reading-report.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'bills',
    component: BillListComponent
  },
  {
    path: 'inward-outward',
    component: InwardOutwardComponent
  },
  {
    path: 'inward-outward/edit/:id',
    component: InwardOutwardComponent
  },
  {
    path: 'inward-outward-list',
    component: InwardOutwardListComponent
  },
  {
    path: 'stock',
    component: StockReportComponent
  },
  {
    path: 'meter-reading',
    component: ReadingComponent
  },
  {
    path: 'reading-report',
    component: ReadingReportComponent
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
