import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ItemService } from 'src/app/services/item.service';
import { InwardOutwardItem } from 'src/app/models/inward-outward-item';
import { ItemMaster } from 'src/app/models/item-master';
import { StaticService } from 'src/app/services/static.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { InwardOutwardService } from 'src/app/services/inward-outward.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { InwardOutward } from 'src/app/models/inward-outward';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { StockItem } from 'src/app/models/stock-item';
import { StockService } from 'src/app/services/stock.service';

@Component({
  selector: 'app-inward-outward',
  templateUrl: './inward-outward.component.html',
  styleUrls: ['./inward-outward.component.css']
})
export class InwardOutwardComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private activeRoute: ActivatedRoute,
    private spinnerService: Ng4LoadingSpinnerService,
    private itemService: ItemService,
    private inOutService: InwardOutwardService,
    private stockService: StockService) { }

  addEditFrom: FormGroup;
  inOutItemList: InwardOutwardItem[] = [];
  itemList: ItemMaster[];
  stockItemList: StockItem[];
  itemStockQty: number;
  selectedItem: ItemMaster;
  successMsg: string = '';
  errorMsg: string = '';
  addNewItemError: string = '';
  deletedInwardOutwardItems: InwardOutwardItem[] = [];
  toList = [];
  fromList = [];
  mainStockObj = {
    key: 'MainStock',
    value: 'Main Stock'
  };
  atRoomStockObj = {
    key: 'AtRoom',
    value: 'At Room'
  };
  runningStockObj = {
    key: 'RunningStock',
    value: 'Running Stock'
  };
  laundryObj = {
    key: 'Laundry',
    value: 'Laundry'
  };
  damageObj = {
    key: 'Damage',
    value: 'Damage'
  };

  msebMeterObj = {
    key: 'MSEBMeter',
    value: 'MSEB Meter'
  };

  msebReportObj = {
    key: 'MSEBReport',
    value: 'MSEB Report'
  };

  waterJarObj = {
    key: 'WaterJar',
    value: 'Water Jar'
  };

  waterJarReportObj = {
    key: 'WaterJarReport',
    value: 'Water Jar Report'
  };


  ngOnInit() {
    this.createAddForm();
    this.fromList.push(this.mainStockObj);
    this.fromList.push(this.atRoomStockObj);
    this.fromList.push(this.runningStockObj);
    this.fromList.push(this.laundryObj);
    this.fromList.push(this.msebMeterObj);
    this.fromList.push(this.waterJarObj);

    if (this.activeRoute.snapshot.params.id) {
      this.loadInwardOutWardDetail(this.activeRoute.snapshot.params.id);
    } else {
      this.loadItemDetails();
    }
  }


  loadInwardOutWardDetail(id: number) {
    if (!id)
      return;

    let getInwardOutward = this.inOutService.getById(id);
    let getInwardOutwardItems = this.inOutService.getInOutItemsById(id);
    let getItemList = this.itemService.getItems();

    this.spinnerService.show();
    forkJoin([getInwardOutward, getInwardOutwardItems, getItemList]).subscribe(
      data => {
        this.itemList = data[2];
        this.setAddEditFormValues(data[0], data[1]);
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    );
  }

  loadItemDetails() {

    let stockRequest = this.stockService.getStockItems();
    let itemRequest = this.itemService.getItems();

    this.spinnerService.show();
    forkJoin([stockRequest, itemRequest]).subscribe(
      data => {
        this.stockItemList = data[0];
        this.itemList = data[1];
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    );

  }

  updateToList() {
    let from = this.addEditFrom.controls.From.value;
    this.toList = [];
    switch (from) {
      case 'MainStock':
        this.toList.push(this.runningStockObj);
        this.toList.push(this.atRoomStockObj);
        break;
      case 'AtRoom':
        this.toList.push(this.runningStockObj);
        break;
      case 'RunningStock':
        this.toList.push(this.atRoomStockObj);
        this.toList.push(this.laundryObj);
        this.toList.push(this.damageObj);
        break;
      case 'Laundry':
        this.toList.push(this.runningStockObj);
        break;
      case 'MSEBMeter':
        this.toList.push(this.msebReportObj);
        break;
      case 'WaterJar':
        this.toList.push(this.waterJarReportObj);
        break;
    }

    if (this.toList.length == 1) {
      this.addEditFrom.controls.To.setValue(this.toList[0].key);
    } else {
      this.addEditFrom.controls.To.setValue('');
    }
  }

  getItemName(itemId: any) {
    return this.itemList.find(item => item.Id === parseInt(itemId)).DisplayItemName;
  }

  selectItem() {
    let selectedItemId = (<FormGroup>this.addEditFrom.controls.AddNewItem).controls.ItemId.value;
    this.itemStockQty = this.stockItemList.find(x => x.ItemId === +selectedItemId)[this.addEditFrom.controls.From.value];
  }


  addNewItem(): void {

    let addNewItemGroup = (<FormGroup>this.addEditFrom.controls.AddNewItem);
    if (addNewItemGroup.invalid) {
      StaticService.validateAllFormFields(addNewItemGroup);
      console.log(addNewItemGroup.errors);
      return;
    }

    if (addNewItemGroup.controls.Qty.value > this.itemStockQty) {
      this.errorMsg = "Enter Item Qty is greater then available stock";
      return;
    }

    let inwardOutwardItemsFormControl = <FormArray>(this.addEditFrom.controls.InwardOutwardItems);
    let newItem = StaticService.copyFormControl(addNewItemGroup);

    let addedItemIds = (<any[]>inwardOutwardItemsFormControl.value).map(x => x.ItemId);

    if (addedItemIds.includes(newItem.value.ItemId)) {
      this.errorMsg = "Item already added";
      return;
    }

    if (this.addEditFrom.controls.Id.value) {
      (<FormGroup>newItem).controls.InOutId.setValue(this.addEditFrom.controls.Id.value)
    }

    inwardOutwardItemsFormControl.push(newItem);

    this.addEditFrom.controls.AddNewItem.reset();
    this.errorMsg = '';
    (<FormGroup>this.addEditFrom.controls.AddNewItem).controls.Id.setValue(0);
    (<FormGroup>this.addEditFrom.controls.AddNewItem).controls.InOutId.setValue(0);
    (<FormGroup>this.addEditFrom.controls.AddNewItem).controls.IsDeleted.setValue(false);

  }

  deleteInOutItem(index: number): void {
    let itemsFormControl = <FormArray>(this.addEditFrom.controls.InwardOutwardItems);
    let selectedItem = (<FormGroup>itemsFormControl.controls[index]);

    if (selectedItem.controls.Id.value) {
      selectedItem.controls.IsDeleted.setValue(true);
      this.deletedInwardOutwardItems.push(selectedItem.value);
    }
    itemsFormControl.removeAt(index);
  }


  saveInwardOutward() {

    if (!this.isFormValid()) {
      return;
    }

    if (!confirm("Are you sure to save data")) {
      return;
    }

    let inwardOutward = this.addEditFrom.value;
    inwardOutward.InwardOutwardItems.push(...this.deletedInwardOutwardItems);

    inwardOutward.User = StaticService.getUserNameAtLocStorage();


    this.spinnerService.show();
    this.inOutService.save(inwardOutward).subscribe(
      data => {
        this.successMsg = data;
        this.addEditFrom.reset();
        let inwardOutwardItems = <FormArray>(this.addEditFrom.controls.InwardOutwardItems);
        inwardOutwardItems.clear();

        // this.addEditFrom.controls.Id.setValue(0);
        // this.addEditFrom.controls.Date.setValue(this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm aa'));

        // (<FormGroup>this.addEditFrom.controls.AddNewItem).controls.Id.setValue(0);
        // (<FormGroup>this.addEditFrom.controls.AddNewItem).controls.InOutId.setValue(0);
        // (<FormGroup>this.addEditFrom.controls.AddNewItem).controls.IsDeleted.setValue(false);

      },
      err => {
        this.errorMsg = err;
      },
      () => this.spinnerService.hide()
    )

  }

  isFormValid(): Boolean {

    if (this.addEditFrom.controls.Date.invalid) {
      this.addEditFrom.controls.Date.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if (this.addEditFrom.controls.From.invalid) {
      this.addEditFrom.controls.From.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if (this.addEditFrom.controls.To.invalid) {
      this.addEditFrom.controls.To.markAsTouched();
      console.log(this.addEditFrom.errors);
      return false;
    }

    if ((<FormArray>(this.addEditFrom.controls.InwardOutwardItems)).length <= 0) {
      this.errorMsg = "Please add Items";
      return false;
    }

    return true;
  }


  setAddEditFormValues(inwardOutward: InwardOutward, inwardOutwardItems: InwardOutwardItem[]) {

    this.addEditFrom.controls.Id.setValue(inwardOutward.Id);
    this.addEditFrom.controls.Date.setValue(this.datePipe.transform(inwardOutward.Date, 'dd-MMM-yyyy hh:mm aa'));

    //this.fromList = this.fromList.filter(x => x.key === inwardOutward.From);
    this.addEditFrom.controls.From.setValue(inwardOutward.From);
    this.updateToList();
    //this.toList = this.toList.filter(x => x.key === inwardOutward.To);
    this.addEditFrom.controls.To.setValue(inwardOutward.To);


    let inOutItemsFormControl = <FormArray>(this.addEditFrom.controls.InwardOutwardItems)
    inwardOutwardItems.forEach(inwardOutwardItem => {
      inOutItemsFormControl.push(this.editInwardOutwardItem(inwardOutwardItem, inwardOutward.Id));
    });
  }


  createAddForm(): void {
    this.addEditFrom = this.formBuilder.group({
      Id: [0],
      Date: [this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm aa'), Validators.required],
      From: ['', Validators.required],
      To: ['', Validators.required],
      User: [],
      AddNewItem: this.createAddNewItem(),
      InwardOutwardItems: this.formBuilder.array([]),
    })
  }

  editInwardOutwardItem(inOutItem: InwardOutwardItem, inOutId: number): FormGroup {
    return this.formBuilder.group({
      Id: [inOutItem.Id],
      ItemId: [inOutItem.ItemId, Validators.required],
      Qty: [inOutItem.Qty, Validators.required],
      IsDeleted: [inOutItem.IsDeleted],
      InOutId: [inOutId]
    });
  }

  createAddNewItem(): FormGroup {
    return this.formBuilder.group({
      Id: [0],
      ItemId: [, Validators.required],
      Qty: [, Validators.required],
      IsDeleted: [false],
      InOutId: [0]
    });
  }

}
