import { Injectable } from '@angular/core';
import { StaticService } from './static.service';
import { HttpClient } from '@angular/common/http';
import { StockItem } from '../models/stock-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = StaticService.BaseServiceUrl + "Stock";

  getStockItems(): Observable<StockItem[]> {
    return this.httpClient.get<StockItem[]>(this.ServiceUrl);
  }
}
