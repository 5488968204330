import { Injectable } from '@angular/core';
import { StaticService } from './static.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = StaticService.BaseServiceUrl + "UserMaster";

  public getUserToken(user: User): Observable<string> {
    let url = this.ServiceUrl + "/GetUserToken";
    return this.httpClient.post<string>(url, user);
  }


  private _IsAdminLogin: boolean = false;
  public get IsAdminLogin(): boolean {
    return this._IsAdminLogin;
  }

  setAdminTokenAtLocStorage(tokenNo: string): void {
    sessionStorage.setItem("UserToken", JSON.stringify(tokenNo));
    this._IsAdminLogin = true;
  }

  getAdminTokenAtLocStorage(): string {
    let adminToken = (JSON.parse(localStorage.getItem("UserToken")) as string);
    this._IsAdminLogin = (adminToken != null);
    console.log(this.IsAdminLogin);
    return adminToken;
  }

  removeAdminTokenAtLocStorage(): void {
    localStorage.removeItem("UserToken");
    this._IsAdminLogin = false;
  }



}
