import { Component, OnInit } from '@angular/core';
import { BillService } from 'src/app/services/bill.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Bill } from 'src/app/models/bill';
import { forkJoin } from 'rxjs';
import { ItemService } from 'src/app/services/item.service';
import { ItemMaster } from 'src/app/models/item-master';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bill-list',
  templateUrl: './bill-list.component.html',
  styleUrls: ['./bill-list.component.css']
})
export class BillListComponent implements OnInit {


  constructor(private billService: BillService,
    private itemService: ItemService,
    private spinnerService: Ng4LoadingSpinnerService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe) { }

  billList: Bill[] = [];
  itemList: ItemMaster[] = [];
  successMsg: string = "";
  errorMsg: string = "";
  billItemData = {};
  searchForm: FormGroup;

  ngOnInit() {
    this.createSearchForm();
    this.loadGridData();
  }

  loadGridData() {

    let searchValue = this.searchForm.value;

    let billRequest = this.billService.get(searchValue);
    let itemRequest = this.itemService.getItems();

    this.spinnerService.show();
    forkJoin([billRequest, itemRequest]).subscribe(
      data => {
        this.billList = data[0];
        this.itemList = data[1];
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  loadBillItems(billId: number) {

    if (this.billItemData[billId]) {
      this.billItemData[billId].showItems = !this.billItemData[billId].showItems;
      return;
    }

    this.spinnerService.show();
    this.billService.getBillItem(billId).subscribe(
      data => {
        this.billItemData[billId] = {
          items: data,
          showItems: true
        }
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  getItemName(itemId: any) {
    return this.itemList.find(item => item.Id === parseInt(itemId)).DisplayItemName;
  }

  SearchBill() {

    let searchValue = this.searchForm.value;

    this.spinnerService.show();
    this.billService.get(searchValue).subscribe(
      data => {
        this.billList = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  getTotalAmount(){
    return this.billList.reduce((sum, current) => sum + current.TotalAmount, 0)
  }


createSearchForm() {
  let fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 6);
  let toDate = new Date();


  // console.log(fromDate.toString());

  this.searchForm = this.formBuilder.group({
    FromDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
    ToDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required]
  })
}

}
