import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reading } from '../models/reading';
import { StaticService } from './static.service';

@Injectable({
  providedIn: 'root'
})
export class ReadingService {

  constructor(private httpClient: HttpClient) { }


  ServiceUrl: string = StaticService.BaseServiceUrl + "Reading/";


  get(): Observable<Reading[]> {
    return this.httpClient.get<Reading[]>(this.ServiceUrl);
  }

  getTodayReading(): Observable<Reading[]> {
    let url = this.ServiceUrl + 'GetTodayReading';
    return this.httpClient.get<Reading[]>(url);
  }

  save(reading: Reading) {
    return this.httpClient.post<string>(this.ServiceUrl, reading);
  }
}
