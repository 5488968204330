import { Injectable } from '@angular/core';
import { InwardOutward } from '../models/inward-outward';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticService } from './static.service';
import { InwardOutwardItem } from '../models/inward-outward-item';

@Injectable({
  providedIn: 'root'
})
export class InwardOutwardService {

  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = StaticService.BaseServiceUrl + "InwardOutward";

  save(inwardOutward: InwardOutward): Observable<string> {
    if (inwardOutward.Id > 0)
      return this.httpClient.put<string>(this.ServiceUrl + "/" + inwardOutward.Id, inwardOutward);

    inwardOutward.Id = 0;
    return this.httpClient.post<string>(this.ServiceUrl, inwardOutward);
  }

  public get(): Observable<InwardOutward[]> {
    return this.httpClient.get<InwardOutward[]>(this.ServiceUrl);
  }

  public getById(id: number): Observable<InwardOutward> {
    let url = this.ServiceUrl + '/' + id
    return this.httpClient.get<InwardOutward>(url);
  }

  public getInOutItemsById(id: number): Observable<InwardOutwardItem[]> {
    let url = this.ServiceUrl + '/GetInOutItems/' + id
    return this.httpClient.get<InwardOutwardItem[]>(url);
  }

  public delete(id: number): Observable<string> {
    let userName = StaticService.getUserNameAtLocStorage();
    let url = this.ServiceUrl + '/DeleteInwardOutward/' + id + '/' + userName;
    return this.httpClient.delete<string>(url);
  }

  public createBillFromOldEntry(): Observable<boolean> {
    let url = this.ServiceUrl + '/CreateBillFromOldEntry';
    return this.httpClient.get<boolean>(url);
  }
}
