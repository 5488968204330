import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InwardOutwardComponent } from './components/inward-outward/inward-outward.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { InwardOutwardListComponent } from './components/inward-outward-list/inward-outward-list.component';
import { StockReportComponent } from './components/stock-report/stock-report.component';
import { BillListComponent } from './components/bill-list/bill-list.component';
import { LoginComponent } from './components/login/login.component';
import { ReadingComponent } from './components/reading/reading.component';
import { ReadingReportComponent } from './components/reading-report/reading-report.component';

@NgModule({
  declarations: [
    AppComponent,
    InwardOutwardComponent,
    InwardOutwardListComponent,
    StockReportComponent,
    BillListComponent,
    LoginComponent,
    ReadingComponent,
    ReadingReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng4LoadingSpinnerModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
