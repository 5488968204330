import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, BehaviorSubject } from 'rxjs';
import { ItemMaster } from '../models/item-master';
import { StaticService } from './static.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private httpClient: HttpClient) {
    this.staticItems.next(this.itemList);
  }

  ServiceUrl: string = StaticService.BaseServiceUrl + "ItemMaster";


  getItems(): Observable<ItemMaster[]> {
    return this.httpClient.get<ItemMaster[]>(this.ServiceUrl);
  }

  // saveItem(item: Item) {
  //   return this.dbService.addItem(item);
  // }

  private staticItems = new BehaviorSubject([]);
  getStaticItems() {
    return this.staticItems.asObservable();
  }


  itemList: ItemMaster[] = [
    {
      Id: 1,
      ItemName: 'Item Item Item 1',
      LaundryCharge: 10,
    },
    {
      Id: 2,
      ItemName: 'Item 2',
      LaundryCharge: 5,
    },
    {
      Id: 3,
      ItemName: 'Item 3',
      LaundryCharge: 5,
    },
    {
      Id: 4,
      ItemName: 'Item 4',
      LaundryCharge: 5,
    },
    {
      Id: 5,
      ItemName: 'Item 5',
      LaundryCharge: 5,
    },
    {
      Id: 6,
      ItemName: 'Item 6',
      LaundryCharge: 5,
    },
    {
      Id: 7,
      ItemName: 'Item 7',
      LaundryCharge: 5,
    },
    {
      Id: 8,
      ItemName: 'Item 8',
      LaundryCharge: 5,
    },
    {
      Id: 9,
      ItemName: 'Item 9',
      LaundryCharge: 5,
    },
    {
      Id: 10,
      ItemName: 'Item 10',
      LaundryCharge: 5,
    }
  ];
}
