import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormArray } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable({
  providedIn: 'root'
})
export class StaticService {

  constructor(private spinnerService: Ng4LoadingSpinnerService) { }

  public static get BaseServiceUrl(): string {
    // return "https://localhost:44353/api/";
     return "https://store.darshanhotel.in/vir_service/api/";
    // return "http://localhost:300/api/";
  }

  public showSpinner() {
    this.spinnerService.show();
  }

  public hideSpinner() {
    this.spinnerService.hide();
  }

  static getUserNameAtLocStorage(): string {
    let adminToken = (JSON.parse(sessionStorage.getItem("UserToken")) as string);
    let adminTokenSplit=adminToken.split('$');
    if(adminTokenSplit.length<2){
      return '';
    }

    return adminTokenSplit[1];
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  static copyFormControl(control: AbstractControl) {
    if (control instanceof FormControl) {
      return new FormControl(control.value);
    } else if (control instanceof FormGroup) {
      const copy = new FormGroup({});
      Object.keys(control.getRawValue()).forEach(key => {
        copy.addControl(key, this.copyFormControl(control.controls[key]));
      });
      return copy;
    } else if (control instanceof FormArray) {
      const copy = new FormArray([]);
      control.controls.forEach(control => {
        copy.push(this.copyFormControl(control));
      })
      return copy;
    }
  }
}
