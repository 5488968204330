import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Reading } from 'src/app/models/reading';
import { ReadingService } from 'src/app/services/reading.service';

@Component({
  selector: 'app-reading-report',
  templateUrl: './reading-report.component.html',
  styleUrls: ['./reading-report.component.css']
})
export class ReadingReportComponent implements OnInit {

  constructor(private readingService: ReadingService,
    private spinnerService: Ng4LoadingSpinnerService) { }

  readingList: Reading[];
  readingListByType: Reading[];
  errorMsg: string;
  readingType: string;

  ngOnInit() {
    this.loadGridData();
    this.readingType = 'MeterReading';
  }

  loadGridData() {
    this.spinnerService.show();
    this.readingService.get().subscribe(
      data => {
        this.readingList = data;
        this.changeReadingType();
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  changeReadingType() {
    this.readingListByType = this.readingList.filter(x => x.ReadingType === this.readingType);
  }

  getTotalTime(reading: Reading): any {
    if (!reading.StartTime || !reading.StopTime) {
      return '';
    }
    
    let totalTime = Math.floor(new Date(reading.StopTime).getTime() - new Date(reading.StartTime).getTime());

    let totalHr = Math.floor(totalTime / (1000 * 60 * 60));
    let divisor_for_minutes = totalTime % (1000 * 60 * 60);
    let totalMinute = Math.floor(divisor_for_minutes / (1000 * 60));
    let divisor_for_seconds = divisor_for_minutes % (1000 * 60);
    let totalSecond = Math.floor(divisor_for_seconds / 1000);

    return totalHr.toString().padStart(2, '0') + ':' + totalMinute.toString().padStart(2, '0');
  }

  getUnit(reading: Reading, currentIndex: number) {

    if (this.readingListByType.length <= currentIndex + 1) {
      return 0;
    }

    let previousReading = this.readingListByType[currentIndex + 1];
    return reading.MeterReading - previousReading.MeterReading;

  }

}
